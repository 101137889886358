import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

export const portfolioItemQuery = graphql`
  query ($slug: String!) {
    portfolio: contentfulPorfolioItem(fields: { slug: { eq: $slug } }) {
      title
      gallery {
        id
        thumbnail: gatsbyImageData(
          width: 400
          height: 300
          layout: FIXED
          placeholder: BLURRED
          formats: [WEBP, PNG]
        )
        original: gatsbyImageData(
          placeholder: BLURRED
          height: 1200
          formats: [WEBP, PNG]
        )
      }
    }
  }
`

export default function PortfolioItem({ data: { portfolio } }) {
  const [imageIndex, setImageIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const images = portfolio.gallery.map(
    image => image.original.images.fallback.src
  )

  const handleImageClick = index => {
    setImageIndex(index)
    setIsModalOpen(true)
    document.body.style.overflowY = "hidden"
    window.scrollTo(0, 0)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    document.body.style.overflowY = "unset"
  }

  const handleNext = () => {
    setImageIndex((imageIndex + 1) % images.length)
  }

  const handlePrev = () => {
    setImageIndex((imageIndex + images.length - 1) % images.length)
  }

  return (
    <PortfolioItemContainer>
      <Seo
        title={portfolio.title}
        image={portfolio.gallery[0]?.thumbnail?.src}
      />
      <ImageGrid>
        {portfolio.gallery.map((image, index) => (
          <ImageWrapper key={image.id} onClick={() => handleImageClick(index)}>
            <GatsbyImage
              image={image.thumbnail}
              alt={`${portfolio.title} lighting design images gallery`}
              placeholder="BLURRED"
              style={{ width: "100%" }}
            />
          </ImageWrapper>
        ))}
      </ImageGrid>
      {isModalOpen && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={handleCloseModal}
          onMovePrevRequest={handlePrev}
          onMoveNextRequest={handleNext}
        />
      )}
    </PortfolioItemContainer>
  )
}

const PortfolioItemContainer = styled.div`
  margin: 4rem 0;
  @media screen and (max-width: 1120px) {
    margin: 4rem 5vw;
  }
  @media screen and (max-width: 480px) {
    margin: 4rem 3vw;
  }
`

const ImageGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 0;
  overflow: hidden;
  & > * {
    flex: 0 0 calc(33.33% - 1rem);
    margin: 0.5rem;
    @media screen and (max-width: 780px) {
      flex: 0 0 calc(50% - 1rem);
    }
    img {
      height: 40rem;
      width: 100%;
    }
    cursor: pointer;
    &:last-child {
      margin-right: auto;
    }
  }
`

const ImageWrapper = styled.div`
  cursor: pointer;
`
